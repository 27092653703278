import { OrgKind } from '@ZettaScaleLabs/continuum-node-client/dist/organization';

export default defineNuxtRouteMiddleware(() => {
    const organisationsStore = useOrganisationsStore();
    const { organisations } = storeToRefs(organisationsStore);
    const org = organisations.value.at(0);

    if (!org || org.kind === OrgKind.DEMO) {
        return navigateTo('/organisation/upgrade');
    }
});
